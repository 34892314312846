import React, { useState } from "react";
import AonContext from "./aonContext";
import commonService from "src/services/commonService";
import authService from "../services/authService";
import config from "../config";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import CachedIcon from "@mui/icons-material/Cached";
import { NoRecordsIcon } from "src/utils/helpers/svgIcons";
import { handleRevertingBackOfUser } from "src/utils/helpers";

const AonStateProvider = ({ children }) => {

  const [projectDetails, setProjectDetails] = useState({
    privileges:[],
    project_id:'',
    project_name:"",
    forceRenderUsers:0
  });
  const [hitNotiCount, setHitNotiCount] = useState(false);

  const [rightSliderDetails, setRightSliderDetails] = useState({
    isCloseOnOverlay : true,
    open : 0,
    type : "switch_org",
    isConnectionCreatedFromConnectionTab : false
  });

  const [connectionCreationDetailsFromConnectionTab, setConnectionCreationDetailsFromConnectionTab] = useState({
    selectedApplicationData : {} 
  })

  const [flowDetails, setFlowDetails] = useState({
    flowState : 'new',
    id : '',
    project_id:'',
    projectName:"",
    name : "",
    description : "",
    is_batch_process: false,
    type : "ondemand",
    dependentFlow: null,
    schedulerState : "none",
    schedulerData : {},
    startDate: "",
    startingAt: "",
    endDate: "",
    runForever: false,
    timeZone: {
      value: "(UTC-04:00) Eastern Daylight Time (US & Canada)",
      offset: "-04:00"
    },
    flowIntervalHour: {label: "", value: ""},
    flowIntervalMin: {label: "", value: ""},
    interval: 0,
    intervalDuration: 0,
    selectedData : { type: '', data : {} },
    sourceApp: {},
    destinationApp : {},
    src_connection_id : '',
    dest_connection_id : '',
    srcApp : {},
    destApp : {},
    action : {
      id: null,
      label: '',
      value: ''
   },
    trigger : {
      id: null,
      label: '',
      value: ''
   },
    action_id : '',
    action_event : "",
    trigger_id : '',
    trigger_event: "create",
    flow_start_time: '',
    next_execution_start_time: '',
    update_by: "",
    filters: [],
    response_field_mapping: [],
    unique_fields: [],
    uniqueFields: {source:[], destination:[], datatype: [], segmentType: []},
    selectedFields : {source:[], destination:[], datatype: [], segmentType: []},
    selectedOtherFields : {source:[], destination:[], datatype: [], segmentType: []},

    finalData : {},
  });

  const [showSideBar, setShowSideBar] = useState(false);

  const [popupDetails, setPopupDetails] = useState({
    open : 1,
    type : "",
    data : null,
  })

  const [forceRender, setForceRender] = useState(0);

  const [showMainLoader, setShowMainLoader] = useState(false);
  const [loggedInUser, setLoggedInUser] = useState({});
  const [jwtToken, setJWTToken] = useState("");

  const [userInvites, setUserInvites] = useState([]);

  const [openSideBar, setOpenSideBar] = useState(false);
  const [userCurrentOrganizationExist, setUserCurrentOrganizationExist] = useState(false);

  function getCurrentOrganization() {
    authService.getCurrentTenant().then((resp) => {
        if (resp.status == 200) {
            commonService.setData("current-org", resp.data.aon_organization);
            authService.setIsUserHaveOrg("true");
            setUserCurrentOrganizationExist(true);
        } else if (resp.status == 209) {
            localStorage.removeItem("current-org");
            authService.setIsUserHaveOrg("false");
            setUserCurrentOrganizationExist(false);
        } else if (resp.status === 404) {
            showDataDoesNotExistDialog();
        }
    });
  };

  const handleClosePopup = () => {
    setPopupDetails({
      open : 1,
      type : "",
      data : null,
    })
  }
  
  const showReloadPopup = () => {
    setPopupDetails({
      open: (popupDetails.open + 1),
      type: "single_button_layout",
      data: {
        icon: <CachedIcon color="primary" sx={(theme) => ({ width: theme.spacing(8), height: theme.spacing(8) })} />,
        heading: "Please Reload Page",
        content: "",
        buttonName: "Reload",
        buttonType: "contained",
        handleButton: () => authService.reloadPage()
      }
    })
  }

  const showUnauthorisedPopup = () => {
    setPopupDetails({
      open: (popupDetails.open + 1),
      type: "single_button_layout",
      data: {
        icon: <WarningAmberIcon color="primary" sx={(theme) => ({ width: theme.spacing(8), height: theme.spacing(8) })} />,
        heading: "You are not authorised to perform this task.",
        content: "",
        buttonName: "OK",
        buttonType: "contained",
        handleButton: () => authService.reloadPage()
      }
    })
  }

  const showUserIsNotPartOfAnyActiveOrgPopup = () => {
    setPopupDetails({
      open: popupDetails.open + 1,
      type: "single_button_layout",
      data: {
        icon: <WarningRoundedIcon color="primary" sx={(theme) => ({ width: theme.spacing(8), height: theme.spacing(8) })} />,
        heading: "Ah Oh!",
        content: "You are not a part of any Acitve Organization",
        buttonName: "Go to Organization",
        handleButton: () => {
          const userObj = JSON.parse(localStorage.getItem("user"));
          delete userObj.role;
        
          localStorage.setItem("user", JSON.stringify(userObj));
          localStorage.removeItem("current-org");
          authService.setIsUserHaveOrg("false");
          setUserCurrentOrganizationExist(false);
          window.location = config.routes.userOrgs
        }
      }
    })
  }

  const showUserIsRemovedFromProjectPopup = () => {
    setPopupDetails({
      open: popupDetails.open + 1,
      type: "single_button_layout",
      data: {
        icon: <WarningRoundedIcon color="primary" sx={(theme) => ({ width: theme.spacing(8), height: theme.spacing(8) })} />,
        heading: "Ah Oh!",
        content: "You have been removed from Project",
        buttonName: "Go to Projects",
        handleButton: () => {window.location = config.routes.projects}
      }
    })
  }

  const [fullScreenPopupDetails, setFullScreenPopupDetails] = useState({
    open : 0,
    type : "field_mapping",
    data : null,
  })

  const [previousFlowData, setPreviousFlowData] = useState({});
  const [fieldMapping, setFieldMapping] = useState({
    selectedFields: {source:[], destination:[], datatype: []},
    selectedOtherFields: {source:[], destination:[], datatype: []},
    uniqueFields: {source:[], destination:[], datatype: []}
  });

  const [entitiesOfSelectedApp, setEntitiesOfSelectedApp] = useState({source:[], destination:[]});

  const showDataDoesNotExistDialog = () => {
    setPopupDetails({
      open: popupDetails.open + 1,
      type: "single_button_layout",
      data: {
        icon: <NoRecordsIcon color="primary" viewBox="0 0 30 28" sx={(theme) => ({width: theme.spacing(8), height: theme.spacing(9)})}/>,
        heading: "Oops, Requested data does not exists",
        content: "We weren't able to find the specific information you're looking for. Please Go back and try again",
        buttonName: "GO BACK",
        handleButton: () => handleRevertingBackOfUser()
      }
    })
  }

  return (
    <AonContext.Provider
      value={{
        projectDetails, setProjectDetails,
        hitNotiCount, setHitNotiCount,
        showSideBar,setShowSideBar,
        popupDetails, setPopupDetails,
        forceRender, setForceRender,
        rightSliderDetails, setRightSliderDetails,
        flowDetails, setFlowDetails,
        showMainLoader, setShowMainLoader,
        loggedInUser, setLoggedInUser,
        jwtToken,
        setJWTToken,
        userInvites,
        setUserInvites,
        connectionCreationDetailsFromConnectionTab, setConnectionCreationDetailsFromConnectionTab,
        openSideBar, setOpenSideBar,
        userCurrentOrganizationExist, setUserCurrentOrganizationExist,
        getCurrentOrganization,
        handleClosePopup,
        showReloadPopup,
        showUserIsNotPartOfAnyActiveOrgPopup,
        showUserIsRemovedFromProjectPopup,
        fullScreenPopupDetails, setFullScreenPopupDetails,
        previousFlowData, setPreviousFlowData,
        fieldMapping, setFieldMapping,
        entitiesOfSelectedApp, setEntitiesOfSelectedApp,
        showDataDoesNotExistDialog, showUnauthorisedPopup
      }}
    >
      {children}
    </AonContext.Provider>
  );
};


export default AonStateProvider;