import { experimental_extendTheme as extendTheme } from "@mui/material/styles";
const theme = extendTheme({
  colorSchemes: {
    light: {
      palette: {
        mode: "light",
        primary: {
          main: "#264fda",
          light: "#5172E1",
          dark: "#1A3798",
          contrastText: "#ffffff",
        },
        secondary: {
          main: "#264fda",
          light: "#5172E1",
          dark: "#1A3798",
          contrastText: "#ffffff",
        },
        background: {
          default: "#f5f5f5",
          paper: "#ffffff",
        },
        error: {
          main: "#cf272d",
          light: "#D85257  ",
          dark: "#901B1F",
          contrastText: "#ffffff",
        },
        warning: {
          main: "#FFA451",
          light: "#FFB673",
          dark: "#B27238",
          contrastText: "#181818",
        },
        info: {
          main: "#3290A6",
          light: "#5BA6B7",
          dark: "#236474",
          contrastText: "#ffffff",
        },
        success: {
          main: "#3D9574",
          light: "#63AA8F",
          dark: "#2A6851",
          contrastText: "#ffffff",
        },
        whitebg: {
          main: "#ffffff",
          contrastText: "#212121"
        },
        greyText: {
          main: "#ffffff",
          contrastText: "#757575",
        },
        customlink: {
          main: "#264fda",
          contrastText: "#ffffff"
        },
        dataGridColor: {
          main: "rgba(38,79,218,0.12)",
        },
        divider: "rgba(0,0,0,0.1)",
        dbBrown: "#AB7D38",
        dbLightBlue: "#F6FBFF",
        dbLightGreen: "#DDF3E1",
        dbLightSkyBlue: "#C9EAF2",
        dbLightBrown: "#F0E6D6",
        dbVeryLightBlue: "#F6F8FB",
        dbVeryLightGreen: "#F1FCF3",
        dbVeryLightSkyBlue: "#EAF7FA",
        dbVeryLightBrown: "#FBF9F6",
        veryLightBlue: "#F7F9FF",
        veryLightGrey: "#FAFAFA",
        loginWhite: "#ffffff",
        cardVeryLightSkyBlueShade1: "#f6f9fe",
        cardVeryLightBlueShade2: "#edf3fc",
        cardLightBlueShade3: "#e5eefb",
        cardBlueShade4: "#dce8f9",
        cardAvatarBackgroundShade: "rgba(0,0,0,0.05)",
        imgInsideAvatarColor: "#DCE9FB",
        progressBarBlueShade1: "#006CB4",
        progressBarBlueShade2: "#008AE6",
        progressBarBlueShade3: "#4DB8FF",
        progressBarBlueShade4: "#CAEBFF",
        greyColorForProgressBar: "#eeeeee",
        dashedLinesColor: "#e0e0e0",
        rightDrawerNoOverlay: "rgba(0, 0, 0, 0)",
        rightDrawerWithOverlay: "rgba(0, 0, 0, 0.5)",
        connectionListBoxOutlineColor: "#e0e0e0",
        textColorForTextInEditConnection: "#000000",
        flowStepConfigInfoColor: "rgba(0,0,0,0.03)",
        flowRunHistoryGreyLine: "#e5e5e5",
        flowRunHistoryActivitesDateLabel: "#fbfbfb",
        stepThreeEntityColor: "#f6f9fe",
        phoneNumberInputBorderColor: "#bdbdbd",
        fieldMappingColor: "#757575",
        addResponseFieldMappingBackgroundColorOfButton: "#F3F2FF",
        addResponseFieldMappingTextColorOfButton: "#264fda",
        backgroundColorForAnElementInTemplateUi: "#EEFFDD",
        textColorForTemplateUiInFieldMapping: "#215100",
        highlightEditedFields: "#F0F3FF",
        hightlightLeftVerticalLineOfEditedFields: "#264fda",
        keyNameColorInListOfRecordScreen: "#757575",
        batchInfoColor: "#cf272d",
        toggleButtonSelected: "#EDF3FC",
        filterFlowActivitiesBgColor: "#FAFAFA",
        applicationSectionOutline:"#e5e5e5",
        blogswiperDotcolor: "rgb(0, 128, 255)"
      },
    },
    dark: {
      palette: {
        mode: "dark",
        primary: {
          main: "#7D9BFF",
          light: "#6380E4",
          dark: "#2A439B",
        },
        secondary: {
          main: "#7D9BFF",
          light: "#6380E4",
          dark: "#2A439B",
        },
        background: {
          default: "#040816",
          paper: "#08102c",
        },
        error: {
          main: "#cf272d",
          light: "#D85257  ",
          dark: "#901B1F",
          contrastText: "#ffffff",
        },
        warning: {
          main: "#FFA451",
          light: "#FFB673",
          dark: "#B27238",
          contrastText: "#181818",
        },
        info: {
          main: "#3290A6",
          light: "#5BA6B7",
          dark: "#236474",
          contrastText: "#ffffff",
        },
        success: {
          main: "#3D9574",
          light: "#63AA8F",
          dark: "#2A6851",
          contrastText: "#ffffff",
        },
        whitebg: {
          main: "#08102c",
          contrastText: "#ffffff"
        },
        greyText: {
          main: "#08102c",
          contrastText: "#BDBDBD",
        },
        customlink: {
          main: "#7D9BFF",
          contrastText: "#264fda"
        },
        dataGridColor: {
          main: "rgba(38,79,218,0.20)",
        },
        divider: "rgba(255,255,255,0.1)",
        dbBrown: "#AB7D38",
        dbLightBlue: "#F6FBFF",
        dbLightGreen: "#DDF3E1",
        dbLightSkyBlue: "#C9EAF2",
        dbLightBrown: "#F0E6D6",
        dbVeryLightBlue: "#F6F8FB",
        dbVeryLightGreen: "#F1FCF3",
        dbVeryLightSkyBlue: "#EAF7FA",
        dbVeryLightBrown: "#FBF9F6",
        veryLightBlue: "#F7F9FF",
        veryLightGrey: "#FAFAFA",
        loginWhite: "#ffffff",
        cardVeryLightSkyBlueShade1: "#164383",
        cardVeryLightBlueShade2: "#12386d",
        cardLightBlueShade3: "#0e2c57",
        cardBlueShade4: "#0b2141",
        cardAvatarBackgroundShade: "rgba(0,0,0,0.3)",
        imgInsideAvatarColor: "#DCE9FB",
        progressBarBlueShade1: "#006CB4",
        progressBarBlueShade2: "#008AE6",
        progressBarBlueShade3: "#4DB8FF",
        progressBarBlueShade4: "#CAEBFF",
        greyColorForProgressBar: "#424242",
        dashedLinesColor: "#424242",
        rightDrawerNoOverlay: "rgba(0, 0, 0, 0)",
        rightDrawerWithOverlay: "rgba(0, 0, 0, 0.5)",
        connectionListBoxOutlineColor: "#757575",
        textColorForTextInEditConnection: "#ffffff",
        flowStepConfigInfoColor: "rgba(255,255,255,0.1)",
        flowRunHistoryActivitesDateLabel: "#353C40",
        stepThreeEntityColor: "#0b2141",
        phoneNumberInputBorderColor: "rgba(255,255,255,0.2)",
        fieldMappingColor: "#757575",
        addResponseFieldMappingBackgroundColorOfButton: "#7D9BFF",
        addResponseFieldMappingTextColorOfButton: "rgba(0, 0, 0, 0.87)",
        backgroundColorForAnElementInTemplateUi: "#215100",
        textColorForTemplateUiInFieldMapping: "#9AFA5F",
        highlightEditedFields: "#0b2141",
        hightlightLeftVerticalLineOfEditedFields: "#a8b9f0",
        keyNameColorInListOfRecordScreen: "#acacac",
        batchInfoColor: "#FFB4AB",
        toggleButtonSelected: "rgba(255,255,255,0.1)",
        filterFlowActivitiesBgColor: "rgba(255,255,255,0.1)",
        blogswiperDotcolor: "rgb(0, 128, 255)"
      },
    },
  },
  spacingValues: {
    value0: 0,
    value1: 1,
    value2: 2,
    value3: 3,
    value4: 4,
    value5: 5,
    value6: 6,
    value40Per: "40%",
    value50Per: "50%",
    valueNeg50Per: "-50%",
    valueNeg60Per: "-60%",
    valueMin520height: "600px",
    valueLoginWidth: "1164px",
    value80px: "80px",
    value152px: "152px",
    value100Per: "100%",
    value100vh: "100vh",
    value80Per: "80%"
  },

  text: {
    center: "center",
    left: "left",
    right: "right",
  },

  gridColumns: {
    gridColumn1: 1,
    gridColumn2: 2,
    gridColumn3: 3,
    gridColumn4: 4,
    gridColumn5: 5,
    gridColumn6: 6,
    gridColumn7: 7,
    gridColumn8: 8,
    gridColumn9: 9,
    gridColumn10: 10,
    gridColumn11: 11,
    gridColumn12: 12,
  },

  space: {
    space0: 0,
    space2: 2,
    space4: 4,
    space8: 8,
    space16: 16,
    space24: 24,
    space32: 32,
    space64: 64,
  },

  display: {
    flex: "flex",
    inline: "inline",
    block: "block",
    inlineBlock: "inline-block",
    none: "none"
  },

  position: {
    absolute: "absolute",
    relative: "relative",
  },

  cursor: {
    pointer: "pointer",
  },

  textTransform: {
    capitalize: "capitalize",
    lowercase: "lowercase",
    uppercase: "uppercase",
  },

  font: {
    size: {
      displaylarge: 72,
      display: 36,
      h1: 32,
      h2: 28,
      h3: 24,
      h4: 18,
      h5: 16,
      h6: 14,
      title: 18,
      label: 12,
      bodyLarge: 16,
      bodyMedium: 14,
      bodySmall: 12,
    },
    weight: {
      bold: '700',
      semiBold: '600',
      medium: '500',
      normal: '400',
      light: '300',
      extraLight: '200',
    },
  },
  border: {
    radius: "12px",
    borderSize: {
      b1: 1,
      b2: 2,
      b3: 3,
    },
  },
  tooltip: {
    placeAtBottom: "bottom",
    placeAtTop: "top"
  },
  typography: {
    fontFamily: "Poppins",
    errorFont: {
      fontSize: "100px",
    },
    h1: {
      fontSize: 32,
    },
    h2: {
      fontSize: 28,
    },
    h3: {
      fontSize: 24,
    },
    h4: {
      fontSize: 18,
    },
    h5: {
      fontSize: 16,
    },
    h6: {
      fontSize: 14,
    },
    body1: {
      fontSize: 14,
    },
    body2: {
      fontSize: 12
    }
  },
  spacing: 8,
  shape: {
    borderRadius: 4,
  },
  components: {
    MuiDataGrid: {
      styleOverrides: {
        row: ({ theme }) => ({
          "&.Mui-selected": {
            backgroundColor: theme.vars.palette.dataGridColor.main,
          },
        }),
        cell: {
          "&.MuiDataGrid-cell:focus-within, ": {
            outline: "none !important",
          },
          caretColor: "transparent !important",
          msOverflowY: "hidden",
        },
        column: ({ theme }) => ({
          "&.MuiDataGrid-columnHeader:focus, ": {
            outline: "solid #3c61de 0px",
          },
          "&.MuiDataGrid-columnHeaderRow": {
            backgroundColor: theme.vars.palette.veryLightGrey
          },
          caretColor: "transparent",
          msOverflowY: "hidden",
        }),
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      hD: 1920,
      res1440: 1440,
      res1366: 1366
    },
  },
  
  overflow: {
    hidden: "hidden",
    auto: "auto"
  },

  rightDrawer: {
    width:"360px",
    height: "600px"
  },
  latestFlowExecutedSection: {
    headerHeight: 54, // header height of Latest flows executed section of dashboard
    rowHeight: 42 // height of a row shown in Latest flows executed section of dashboard
  }
});

export default theme;
