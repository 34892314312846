/** 
 * Author: Hemant Sharma
 * Type: Helper 
 * Objective: To provide helper functions 
 * Associated Route/Usage: Global 
*/ 

import config from "src/config";
import service from "src/services/userManagementService";
import { toast } from "react-toastify";
import pkceChallenge from "pkce-challenge";
import { useEffect, useState } from "react";
import messages from "../data/messages";

/**
   * Create an array which can be used as options of Dropdown
   * @items : Array of Object
   * @label : String
   * @value : String
   * @otherFields : Array of String
   * @returns : Array of Object
*/
function convertToSelectOptions(items=[], label='label', value='value', otherFields=[]) {
  if(items && items instanceof Array){
    return items.map((item)=>{
      const obj = {
        label : item[label],
        value : item[value]
      }
      if(otherFields.length > 0){
        for (let i = 0; i < otherFields.length; i++)
        {
          obj[otherFields[i]] = (item[otherFields[i]])?item[otherFields[i]]: typeof (item[otherFields[i]]) === "boolean" ? false : null;
        }
      }
      return obj
    })
  } else{
    return [{
      label : "No data to select",
      value : ""
    }];
  }
}

/**
   * Used to filter an Entity fields and returns only reference fields
   * @items : Array of Object
   * @returns : Array of Object
*/
function fetchRelations(items) {
  if(items && items instanceof Array){
    return items.filter((item)=>item.actualType === 'reference')
  } else{
    return [{
      label : "No data to select",
      value : ""
    }];
  }
}

/**
   * Used to filter an Entity fields and returns non reference fields
   * @items : Array of Object
   * @returns : Array of Object
*/
function fetchOptionsWithoutRelations(items) {
  if(items && items instanceof Array){
    return items.filter((item)=>item.actualType !== 'reference')
  } else{
    return [{
      label : "No data to select",
      value : ""
    }];
  }
}

/**
   * Used to Validate a string for Password 
   * Atleast (1 lower char, 1 upper char, 1 special char and 1 number) and min length should be 8 char
   * @value : String
   * @returns : Boolean
*/
function validatePassword (value) {
    const regexp =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#^()])[A-Za-z\d@$!%*?&#^()|]{8,}$/;
    return regexp.test(value);
};

/**
   * Used to Validate a string for Email 
   * Atleast @ and . should be there 
   * min 3 char should be after @ and min 2 char should be after .
   * @value : String
   * @returns : Boolean
*/  
function validateEmail (email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
};
  
/**
   * Used to Validate a string for URL 
   * Atleast . should be there 
   * min 2 char should be after . and also verify the HTTP and HTTPS
   * @value : String
   * @returns : Boolean
*/  
function isValidUrl(value) {
    const regEx = /^((http(s?)?):\/\/)?(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/;
    return regEx.test(value);
  }
  
/**
   * Used to Validate a string for Name 
   * Atleast 2 char should be there 
   * There should not be any number and special chars
   * @value : String
   * @returns : Boolean
*/  
function isValidName(value) {
    return /^([a-zA-Z ]){3,128}$/i.test(value);
}
  
/**
   * Used to check if any value is exist in Array or not?
   * @searchValue : String
   * @arrayToSearch : Array
   * @returns : Boolean
*/  
function inArray (searchValue, arrayToSearch) {
    var length = arrayToSearch.length;
    for(var i = 0; i < length; i++) {
        if(arrayToSearch[i] === searchValue)
            return true;
    }
    return false;
}
  
/**
   * Used to encrypt a string
   * @text : String
   * @returns : String
*/  
function crypt (text) {
    const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
    const byteHex = (n) => ("0" + Number(n).toString(16)).substr(-2);
    const applySaltToChar = (code) => textToChars(config.cryptKey).reduce((a, b) => a ^ b, code);
  
    return text
        .split("")
        .map(textToChars)
        .map(applySaltToChar)
        .map(byteHex)
        .join("");
};
  
/**
   * Used to decrypt a string
   * @text : String
   * @returns : String
*/  
function decrypt (encoded) {
    const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
    const applySaltToChar = (code) => textToChars(config.cryptKey).reduce((a, b) => a ^ b, code);
    return encoded
        .match(/.{1,2}/g)
        .map((hex) => parseInt(hex, 16))
        .map(applySaltToChar)
        .map((charCode) => String.fromCharCode(charCode))
        .join("");
};
  
/**
   * Used to Validate a string for Alphanumeric 
   * Only Char and Number should there
   * @value : String
   * @returns : Boolean
*/  
function isAlphanumeric (str) {
    return str.match("^[A-Za-z0-9]+$");
} 
  
/**
   * Used to change the format of timezone offset
   * @val : number
   * @isNegative : Boolean
   * @returns : String
*/  
function changeOffsetFormat (val, isNegative=false) {
    const offsetInt = (isNegative)?(val * -1):val;
    let mins = offsetInt % 60;
    let hours = (offsetInt - mins) / 60;
    hours = hours < 10 ? '0'+hours : hours;
    mins = mins < 10 ? '0'+mins : mins;
    return (hours + ':' + mins)
}
  
/**
   * Used to get the user's current timezone offset
   * @void
   * @returns : String
*/  
function getOffset() {
    const dhks = new Date();
    const offsetDiff = dhks.getTimezoneOffset();
    if(offsetDiff < 1){
        if(offsetDiff === 0){
            return "+00:00";
        } else{
            let final = '+' + changeOffsetFormat(offsetDiff, true);
            return final;
        }    
    } else{
        let final = '-' + changeOffsetFormat(offsetDiff, false);
        return final;
    }
}

function removeIdTokenTimeLeftFromSessionStorage() {
  sessionStorage.removeItem("session_id");
  sessionStorage.removeItem("session_token");
  sessionStorage.removeItem("time_left");
}

function toTitleCase(str) {
  if(str && str.trim() !== "") {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }
  return "";
}

function inputFieldKeyToLabel(key) {
  return key.replace('_', ' ').replace(/\w\S*/g, (word) => { return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase() });
};

function changeUserNameToCharacterForAvatar(name) {
  if(name) {
    if (name.trim().split(" ").length > 1) {
      let findName = name.split(" ").filter((ele) => {
        if(ele.trim() !== "") {
          return ele;
        }
      })
      return {
        children: `${findName[0][0]}${findName[1][0]}`,
      };
    } else {
      let findName = name.split(" ").filter((ele) => {
        if(ele.trim() !== "") {
          return ele;
        }
      })
      return {
        children: `${findName[0][0]}`,
      }
    }
  } else {
    return {
      children: ""
    }
  }
}

function handleAdditionOfExtensionInIconName(path, iconName, extensionToBeAdded) {
  return `${path}/${iconName}.${extensionToBeAdded}`;
}

function fetchFunctionField(element) {
  let functionName = element.function;
  if(functionName === "conditional") {
    let ifValueData = element.value && element.value.length > 0 ? typeof element.value[0].ifValue === "string" ? '"' + element.value[0].ifValue + '"' : element.value[0].ifValue.label : null;
    if(element.value && element.value.length > 0 && element.value[0].label && element.value[0].label === "meta_data" && element.value[0].user_input && element.value[0].user_input.trim() !== "" && ifValueData && ifValueData === "meta_data" && element.value[0].ifValue.user_input && element.value[0].ifValue.user_input.trim() !== "") {
      return `${functionName.toUpperCase()}(${element.value[0].path ? element.value[0].path.includes('$$') ? element.value[0].path.replaceAll('$$', '/') + '.' + element.value[0].user_input : element.value[0].path + '.' + element.value[0].user_input : element.value[0].label + '.' + element.value[0].user_input}, ${ifValueData}.${element.value[0].ifValue.user_input})`
    }
    if(element.value && element.value.length > 0 && element.value[0].label && element.value[0].label === "meta_data" && element.value[0].user_input && element.value[0].user_input.trim() !== "") {
      return `${functionName.toUpperCase()}(${element.value[0].path ? element.value[0].path.includes('$$') ? element.value[0].path.replaceAll('$$', '/') + '.' + element.value[0].user_input : element.value[0].path + '.' + element.value[0].user_input : element.value[0].label + '.' + element.value[0].user_input}, ${ifValueData})`
    }
    if(ifValueData && ifValueData === "meta_data" && element.value[0].ifValue.user_input && element.value[0].ifValue.user_input.trim() !== "") {
      return `${functionName.toUpperCase()}(${element.value[0].path ? element.value[0].path.includes('$$') ? element.value[0].path.replaceAll('$$', '/') : element.value[0].path : element.value[0].label}, ${ifValueData}.${element.value[0].ifValue.user_input})`
    }
    return ifValueData ? `${functionName.toUpperCase()}(${element.value[0].path ? element.value[0].path.includes('$$') ? element.value[0].path.replaceAll('$$', '/') : element.value[0].path : element.value[0].label}, ${ifValueData})` : null;
  }
  const inputArray = [];
  element.value.map((ele) => {
    if(ele.beforeConstant && ele.afterConstant) {
      inputArray.push(' "' + ele.beforeConstant + '"');
      if(ele.label === "meta_data" && ele.user_input && ele.user_input.trim() !== "") {
        inputArray.push(` ${formatStringByRemovingDoubleQuotes(ele.label)}.` + ele.user_input);
      } else {
        inputArray.push(` ${formatStringByRemovingDoubleQuotes(ele.label)}`);
      }
      inputArray.push(' "' + ele.afterConstant + '"');
    } else if(ele.beforeConstant) {
      inputArray.push(' "' + ele.beforeConstant + '"');
      if(ele.label === "meta_data" && ele.user_input && ele.user_input.trim() !== "") {
        inputArray.push(` ${formatStringByRemovingDoubleQuotes(ele.label)}.` + ele.user_input);
      } else {
        inputArray.push(` ${formatStringByRemovingDoubleQuotes(ele.label)}`);
      }
    } else if(ele.afterConstant) {
      if(ele.label === "meta_data" && ele.user_input && ele.user_input.trim() !== "") {
        inputArray.push(` ${formatStringByRemovingDoubleQuotes(ele.label)}.` + ele.user_input);
      } else {
        inputArray.push(` ${formatStringByRemovingDoubleQuotes(ele.label)}`);
      }
      inputArray.push(' "' + ele.afterConstant + '"');
    } else {
      if(ele.label === "meta_data" && ele.user_input && ele.user_input.trim() !== "") {
        inputArray.push(` ${formatStringByRemovingDoubleQuotes(ele.label)}.` + ele.user_input);
      } else {
        inputArray.push(` ${formatStringByRemovingDoubleQuotes(ele.label)}`);
      }
    }
  })
  return `${functionName.toUpperCase()}(${inputArray})`;
}

function fetchLookupField(element, forSlider) {
  if(Array.isArray(element.sourceEntityField) && (element.fieldType === "targetLookup" || forSlider)) {
    const inputArray = [];
    element.sourceEntityField.map((ele) => {
      if(ele.beforeConstant && ele.afterConstant) {
        inputArray.push(' "' + ele.beforeConstant + '"');
        if(ele.label === "meta_data" && ele.user_input && ele.user_input.trim() !== "") {
          inputArray.push(' [' + ele.segment.value + '/' + formatStringByRemovingDoubleQuotes(ele.label) + '.' + ele.user_input + ']')
        } else {
          inputArray.push(' [' + ele.segment.value + '/' + formatStringByRemovingDoubleQuotes(ele.label) + ']')
        }
        inputArray.push(' "' + ele.afterConstant + '"');
      } else if(ele.beforeConstant) {
        inputArray.push(' "' + ele.beforeConstant + '"');
        if(ele.label === "meta_data" && ele.user_input && ele.user_input.trim() !== "") {
          inputArray.push(' [' + ele.segment.value + '/' + formatStringByRemovingDoubleQuotes(ele.label) + '.' + ele.user_input + ']');
        } else {
          inputArray.push(' [' + ele.segment.value + '/' + formatStringByRemovingDoubleQuotes(ele.label) + ']');
        }
      } else if(ele.afterConstant) {
        if(ele.label === "meta_data" && ele.user_input && ele.user_input.trim() !== "") {
          inputArray.push(' [' + ele.segment.value + '/' + formatStringByRemovingDoubleQuotes(ele.label) + '.' + ele.user_input + ']')
        } else {
          inputArray.push(' [' + ele.segment.value + '/' + formatStringByRemovingDoubleQuotes(ele.label) + ']')
        }
        inputArray.push(' "' + ele.afterConstant + '"');
      } else {
        if(ele.label === "meta_data" && ele.user_input && ele.user_input.trim() !== "") {
          inputArray.push(' [' + ele.segment.value + '/' + formatStringByRemovingDoubleQuotes(ele.label) + '.' + ele.user_input + ']')
        } else {
          inputArray.push(' [' + ele.segment.value + '/' + formatStringByRemovingDoubleQuotes(ele.label) + ']')
        }
      }
    })
    return forSlider ? `${inputArray.join(" ")}` : `${inputArray.join(" ")} [{${element.lookupEntity.label}/${element.lookupValue.label}}]`;
  }
  // TODO - Make the following code structure dynamic
  if(element.sourceEntityField && (element.sourceEntityField.label || (element.sourceEntityField.path))) {
    if(element.lookupValue.label) {
      let parentField = element.sourceEntityField.label ? element.sourceEntityField.label : element.sourceEntityField.path ? element.sourceEntityField.path : ""
      let lookupEntity = element.lookupEntity.label
      let lookupValue = element.lookupValue.label
      return parentField === "meta_data" ? `${parentField}.${element.sourceEntityField.user_input}[{${lookupEntity}/${lookupValue}}]` : `${parentField}[{${lookupEntity}/${lookupValue}}]`
    }
    if(element.lookupValue.lookupValue &&
      element.lookupValue.lookupValue.label
    ) {
      let parentField = element.sourceEntityField.label
      let lookupEntity = element.lookupEntity.label
      let lookupValue = element.lookupValue.sourceEntityField.label
      let nestedLookupOneEntity = element.lookupValue.lookupEntity.label
      let nestedLookupOneField = element.lookupValue.lookupValue.label
      return `${parentField}[{${lookupEntity}/${lookupValue}}, {${nestedLookupOneEntity}/${nestedLookupOneField}}]`
    }
    let parentField = element.sourceEntityField.label
    let lookupEntity = element.lookupEntity.label
    let lookupValue = element.lookupValue.sourceEntityField.label
    let nestedLookupOneEntity = element.lookupValue.lookupEntity.label
    let nestedLookupOneField = element.lookupValue.sourceEntityField.label
    let nestedLookupTwoEntity = element.lookupValue.lookupValue.lookupEntity.label
    let nestedLookupTwoField = element.lookupValue.lookupValue.lookupValue.label
    return `${parentField}[{${lookupEntity}/${lookupValue}}, {${nestedLookupOneEntity}/${nestedLookupOneField}}, {${nestedLookupTwoEntity}/${nestedLookupTwoField}}]`
  }
  if(element && element.lookupField)
    return `${element.lookupValue.value} (${element.lookupField.label}/${element.lookupEntity.label})`;
  return `${element.lookupValue.value} (${element.relatedField.label}/${element.lookupEntity.label})`;
}

function formatGivenValue(value, symbolToBeReplaced, newSymbol) {
  return value.includes(symbolToBeReplaced) ? value.replaceAll(symbolToBeReplaced, newSymbol) : value
}

function checkMetaDataAndItsValueExistInGivenFieldType(element) {
  return element?.label === "meta_data" && element?.user_input?.trim() !== "";
}

function handleRepresentationOfFieldOnUi(element, forSlider=false) {
  if(forSlider)
    return fetchLookupField(element, forSlider);
  switch(element?.fieldType) {
    case "constant": {
      if(element.path)
        return formatGivenValue(element.path);
      let isElementTypeBool = (element?.type?.value === 'boolean' && typeof element?.value === "object")
      return isElementTypeBool ? `${element.value.label} (constant)` : `${element.value} (constant)`
    }

    case "relation": {
      let string = `${element.parent}.${element.label}`
      if(checkMetaDataAndItsValueExistInGivenFieldType(element))
        return `${string}.${element.user_input}`
      return string;
    }

    case "function":
      return fetchFunctionField(element);

    case "childRelation":
      return `${element.child_relation_entity}/${element.label}`

    case "sourceLookup":
    case "targetLookup":
      return fetchLookupField(element, forSlider);

    default: {
      if(element) {
        if(checkMetaDataAndItsValueExistInGivenFieldType(element))
          return `${element.label}.${element.user_input}`
        let label = element.path ? formatGivenValue(element.path) : element.label
        return label ? label : element
      }
      return element
    }
  }
}

function formatStringByRemovingDoubleQuotes(string) {
  return string ? string.replace(/^"(.*)"$/, '$1') : string;
}

function handleRemoveUserFromOrganizationApi(id) {
  return service.removeUser({tenantId: id}).then((resp) => {
    if(resp.status === 419) {
      toast.error(resp.message);
    } else {
      return resp;
    }
  })
}

function handleRoleChangeInOrganization(data) {
  return service.updateUserRole(data).then((resp) => {
    if (resp.status === 409) {
      toast.error(resp.message);
    } else if (resp.status === 419) {
      toast.error(resp.message);
    } else {
      return resp;
    }
  })
}

function isInputFieldEmpty(value, isSpaceValid=false) {
  if(isSpaceValid) {
    return !value;
  }
  return value.trim() === "";
}

function checkWhetherRequiredKeyExistsOrNot(keyName, details)  {
  return Object.keys(details.current.location).find((ele) => {return ele === keyName})
}

function checkGivenNameExistsOrNotInGivenString(string, keyName) {
  return string.indexOf(keyName);
}

function handleRevertingBackOfUser() {
  let checkIsUserLoggedIn = checkGivenNameExistsOrNotInGivenString(window.location.href, "home") > -1
  let checkIsLocationProjectRelated = checkGivenNameExistsOrNotInGivenString(window.location.href, "projects") > -1
  let checkIsLocationFlowRelated = checkGivenNameExistsOrNotInGivenString(window.location.href, "flows") > -1
  let checkIsLocationConnectionRelated = checkGivenNameExistsOrNotInGivenString(window.location.href, "connection") > -1 || checkGivenNameExistsOrNotInGivenString(window.location.href, "connections") > -1
  let checkIsLocationFlowRunHistoryRelated = checkGivenNameExistsOrNotInGivenString(window.location.href, "flow-run-history") > -1

  if(checkIsUserLoggedIn) {
    if(checkIsLocationProjectRelated || checkIsLocationFlowRelated) {
      window.location = "/home/projects";
    } else if(checkIsLocationConnectionRelated) {
      window.location = "/home/connections";
    } else if(checkIsLocationFlowRunHistoryRelated) {
      window.location = "/home/flow-run-history";
    } else {
      window.location = "/home";
    }
  } else {
    window.location = config.routes.login
  }
}

function usePKCEGenerator() {
  const [obj, setObj] = useState(null);
  useEffect(() => {
    const fetchCodeChallengeAndVerifier = () => {
      pkceChallenge(128).then((code) => {
        setObj(code);
      });
    }

    fetchCodeChallengeAndVerifier();
  },[])

  return obj;
}

/**
  * Used to check given name is valid or not
  * According to this expression only these characters are considered to be valid: _, @, $, -, numbers, a-z, A-Z
  * Invalid characters: +, =, {, }, [, ], ^, *, #, !, /, ., :, ;, |, \, %, <, >, `, ~, (, ), ?,"", '', ','
*/ 
function isGivenNameValid(name) {
  const regexp = /^[a-zA-Z0-9$@\-_][a-zA-Z0-9$@\-_ ]*[a-zA-Z0-9$@\-_]$/;
  return regexp.test(name);
}

function convertStatusToShowOnUi(status) {
  switch(status) {
    case "empty_source_data": {
      return "No Data At Source";
    }
    case "partial_success": {
      return "Partial Success";
    }

    case "failure-at-destination": {
      return "Failed At Destination";
    }

    default: {
      return status;
    }
  }
}

function handleActionEvents(selectedAppName, triggerEvent) {
  if(selectedAppName === "Business Central") {
    if(triggerEvent === "delete")
      return ["Delete"];
    return ["Create", "Update", "Upsert", "Delete"];
  }
  if(selectedAppName === "Woo Commerce")
    return ["Create", "Update"];
  return ["Create", "Update", "Upsert"];
}

const getHelperText = key => messages.helperTextList[key] ?? '';

export { 
  convertToSelectOptions,
  fetchRelations,
  fetchOptionsWithoutRelations,
  validatePassword, 
  validateEmail, 
  isValidUrl, 
  isValidName,
  inArray, 
  crypt, 
  decrypt,
  isAlphanumeric,
  getOffset,
  removeIdTokenTimeLeftFromSessionStorage,
  toTitleCase,
  inputFieldKeyToLabel,
  changeUserNameToCharacterForAvatar,
  handleAdditionOfExtensionInIconName,
  handleRepresentationOfFieldOnUi,
  handleRemoveUserFromOrganizationApi,
  handleRoleChangeInOrganization,
  formatStringByRemovingDoubleQuotes,
  isInputFieldEmpty,
  checkWhetherRequiredKeyExistsOrNot,
  checkGivenNameExistsOrNotInGivenString,
  handleRevertingBackOfUser,
  usePKCEGenerator,
  isGivenNameValid,
  convertStatusToShowOnUi,
  handleActionEvents,
  getHelperText
};